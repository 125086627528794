import React, { useEffect, useLayoutEffect, useState } from 'react';
// import LogoBack from "./assets/logo/back.svg";
import { ReactComponent as LogoBack2 } from "./assets/logo/back.svg";
import logo from './logo.svg';
import './App.css';
import { randomInt } from 'crypto';

const fontFamilies = [
  "MoiraiOne-Regular",
  "RubikPuddles-Regular",
  "FasterOne-Regular"
]

const fontFamily = fontFamilies[Math.floor(Math.random() * (fontFamilies.length))]

function App() {
  const ratio = 400 / 114
  const ratioRa = 77 / 99
  const ratioTsu = 55 / 56
  const ratioKu = 72 / 98
  const sizeRatioRa = 5
  const sizeRatioTsu = 6.7
  const sizeRatioKu = 5.4
  const [size, setSize] = useState([0, 0]);
  const padding = size[0] / 70
  const [intervalSec, setIntervalSec] = useState(1);
  const [now, setNow] = useState(new Date())

  useLayoutEffect(() => {
    const width = window.innerWidth - 100
    const updateSize = (): void => {
      setSize([width, width / ratio]);
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  // let set = [
  //   "#" + Math.floor(Math.random()*16777215).toString(16),
  //   "#" + Math.floor(Math.random()*16777215).toString(16),
  //   "#" + Math.floor(Math.random()*16777215).toString(16),
  //   "#" + Math.floor(Math.random()*16777215).toString(16),
  //   "#" + Math.floor(Math.random()*16777215).toString(16),
  //   "#" + Math.floor(Math.random()*16777215).toString(16),
  // ]

  const [ colors, setColors ] = useState<string[]>( [
    "#282c34",
    "#282c34",
    "#282c34",
    "#282c34",
    "#282c34",
    "#282c34",
  ] );

  // set = [
  //   colorA,
  //   colorB,
  //   colorB,
  //   colorB,
  //   colorB,
  //   colorB,
  // ]

  let i = 1

  function randColor(min = 220) {
    const r = (Math.floor((Math.random() *255 - min)) + min).toString(16).padStart(2, '0')
    const g = (Math.floor((Math.random() *255 - min)) + min).toString(16).padStart(2, '0')
    const b = (Math.floor((Math.random() *255 - min)) + min).toString(16).padStart(2, '0')
    return "#" + r + g + b
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      // const r = Math.floor((Math.random() *255 - 100) + 100).toString(16)
      // const g = Math.floor((Math.random() *255 - 100) + 100).toString(16)
      // const b = Math.floor((Math.random() *255 - 100) + 100).toString(16)
      const colorA = randColor()
      // const colorB = "#" + Math.floor(Math.random()*16777215).toString(16)
      const colorB = randColor()
      // let set: string[] = JSON.parse(JSON.stringify(colors))
      let set = [
        colorB,
        colorB,
        colorB,
        colorB,
        colorB,
        colorB,
      ]
      if (i > set.length - 1) i = 1;
      set[i] = colorA
      setColors(set)
      i++;
    }, intervalSec * 1000)

    const intevalIdTime = setInterval(() => {
      setNow(new Date())
    }, 1000)

    return () => {
      clearInterval(intervalId);
      clearInterval(intevalIdTime);
    };
  }, []);

  const nowTime = now.toLocaleDateString("ja-JP", {year: "numeric",month: "2-digit",
    day: "2-digit", hour: "2-digit",minute: "2-digit",
    second: "2-digit"}).substring(10)

  let nowDate = now.toLocaleDateString("ja-JP", {year: "numeric",month: "2-digit",
    day: "2-digit"})

  const panelTimeWidth = size[0] / 15

  nowDate += `(${['sun','mon','tue','wed','thu','fri','sat'][now.getDay()]})`;

  const panelTimeCSS: React.CSSProperties = {
    fontSize: size[1] / 4, 
    // backgroundColor: "#000000", 
    fontFamily: fontFamily, 
    textAlign:"justify", 
    width: panelTimeWidth, 
    textAlignLast: "center",
    float: "left",
    opacity: 0.9
    // marginLeft: size[0] / 60
  }

  const panelDate: JSX.Element[] = []
  const panelTime: JSX.Element[] = []


  for(const s of nowDate.split("")) {
    panelDate.push((
      <div style={panelTimeCSS}>
        {s}
      </div>
    ))
  }

  for(const s of nowTime.split("")) {
    panelTime.push((
      <div style={panelTimeCSS}>
        {s}
      </div>
    ))
  }

  return (
    <div className="App">
      <header className="App-header">
        <div style={{position: "relative", opacity: 0.9}}>
          <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width={size[0]} height={size[1]}
            viewBox="0 0 400 114"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,114.000000) scale(0.100000,-0.100000)"
            fill={colors[0]} stroke="none"
            style={{
              WebkitTransition: `all ${intervalSec}s ease`,
              MozTransition: `all ${intervalSec}s ease`,
            }}
            >
            <path d="M160 1127 c-44 -22 -70 -73 -70 -139 0 -66 -8 -85 -57 -142 l-33 -40
            0 -192 c0 -109 5 -205 10 -220 6 -15 24 -42 40 -61 29 -32 30 -37 30 -131 0
            -114 17 -153 78 -179 44 -19 281 -26 405 -13 177 19 318 132 352 283 12 55 36
            80 56 60 10 -10 10 -18 1 -38 -7 -14 -12 -66 -12 -116 0 -88 1 -90 34 -127
            l34 -37 108 2 c171 4 262 35 343 115 52 52 71 86 85 157 6 30 16 56 21 58 6 2
            78 -56 159 -128 82 -72 175 -153 208 -180 l59 -49 132 0 c110 0 139 4 176 20
            54 25 55 25 110 -2 43 -21 59 -23 236 -23 168 0 198 3 260 22 141 45 224 124
            261 251 15 54 27 77 37 77 9 0 92 -66 184 -147 245 -213 207 -192 349 -196
            149 -4 197 7 224 51 22 35 27 117 11 160 -5 13 -91 100 -190 193 -133 124
            -181 174 -179 189 2 12 60 66 142 132 77 62 159 132 184 155 l44 43 -4 72 c-3
            64 -7 76 -31 100 -27 27 -30 28 -164 31 -75 2 -151 1 -168 -2 -58 -11 -408
            -309 -462 -394 -18 -28 -39 -52 -46 -52 -7 0 -27 14 -45 31 -30 28 -33 35 -25
            63 4 17 8 84 8 148 0 115 0 117 -29 147 -47 49 -78 54 -335 55 -228 1 -237 0
            -272 -21 -46 -29 -52 -29 -97 2 -35 24 -44 25 -178 25 -171 0 -168 1 -353
            -153 -172 -144 -239 -211 -262 -263 -11 -24 -23 -46 -28 -49 -5 -2 -35 11 -67
            30 l-58 35 -156 0 c-171 0 -240 -13 -297 -56 -17 -13 -38 -24 -47 -24 -8 0
            -39 22 -68 49 -53 48 -53 49 -40 83 7 19 12 81 12 142 l0 107 -38 40 -38 41
            -104 8 c-162 14 -411 12 -440 -3z m2104 -323 c3 -9 6 -98 6 -199 0 -192 5
            -219 45 -267 26 -30 33 -58 15 -58 -11 0 -123 98 -260 228 l-95 90 35 37 c38
            40 226 185 239 185 5 0 12 -7 15 -16z"/>
            </g>
          </svg>
          <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            style={{
              position: "absolute",
              top: padding,
              left: padding
            }}
            width={size[0] / sizeRatioRa} height={size[0] / sizeRatioRa / ratioRa}
            viewBox="0 0 77.000000 99.000000"
            preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,99.000000) scale(0.100000,-0.100000)"
              fill={colors[1]} stroke="none"
              style={{
                WebkitTransition: `all ${intervalSec}s ease`,
                MozTransition: `all ${intervalSec}s ease`,
              }}
            >
            <path d="M133 980 c-12 -5 -26 -18 -32 -29 -16 -30 -13 -152 4 -175 18 -23 5
            -36 -36 -36 -14 0 -35 -10 -47 -23 -21 -22 -22 -33 -22 -189 0 -152 2 -167 21
            -192 19 -24 26 -26 97 -26 74 0 81 2 147 43 111 69 167 82 225 52 51 -26 54
            -104 7 -139 -26 -19 -43 -21 -204 -23 -214 -2 -213 -1 -213 -108 0 -55 4 -78
            17 -94 26 -31 116 -44 258 -38 188 8 294 51 365 149 41 56 53 108 48 203 -6
            124 -56 202 -162 257 -60 32 -218 37 -303 10 -34 -11 -65 -17 -68 -14 -3 3 -5
            22 -5 42 0 22 -8 48 -20 63 -26 33 -25 38 4 33 13 -2 105 -6 206 -8 217 -4
            210 -7 210 112 0 105 -14 118 -141 130 -118 12 -327 12 -356 0z"/>
            </g>
          </svg>
          <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            style={{
              position: "absolute",
              top: size[0] / 9,
              left: size[0] / 4.3
            }}
            width={size[0] / sizeRatioTsu} height={size[0] / sizeRatioTsu / ratioTsu}
            viewBox="0 0 55.000000 56.000000"
            preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,56.000000) scale(0.100000,-0.100000)"
              fill={colors[2]} stroke="none"
              style={{
                WebkitTransition: `all ${intervalSec}s ease`,
                MozTransition: `all ${intervalSec}s ease`,
              }}
            >
            <path d="M112 544 c-97 -26 -112 -44 -112 -133 0 -86 33 -95 204 -58 75 17
            113 11 133 -20 15 -22 15 -27 0 -58 -20 -42 -58 -62 -150 -80 -85 -16 -107
            -36 -107 -100 0 -28 7 -51 22 -70 21 -27 22 -28 109 -21 159 12 244 54 303
            149 56 91 44 253 -27 334 -51 58 -95 73 -217 72 -58 0 -129 -7 -158 -15z"/>
            </g>
          </svg>
          <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            style={{
              position: "absolute",
              top: padding,
              left: size[0] / 4.3 + size[0] / sizeRatioTsu + padding / 2
            }}
            width={size[0] / sizeRatioKu} height={size[0] / sizeRatioKu / ratioKu}
            viewBox="0 0 72.000000 98.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,98.000000) scale(0.100000,-0.100000)"
              fill={colors[3]} stroke="none"
              style={{
                WebkitTransition: `all ${intervalSec}s ease`,
                MozTransition: `all ${intervalSec}s ease`,
              }}
            >
            <path d="M448 970 c-16 -5 -47 -23 -70 -41 -86 -67 -274 -228 -310 -265 -69
            -72 -86 -160 -47 -240 22 -47 139 -163 324 -321 l119 -103 113 0 c104 0 113 2
            127 22 36 52 45 41 -378 439 l-60 57 35 32 c19 17 116 97 217 177 183 147 208
            175 185 212 -5 9 -21 22 -34 28 -27 14 -178 16 -221 3z"/>
            </g>
          </svg>
          <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            style={{
              position: "absolute",
              top: padding,
              left: size[0] / 4.3 + size[0] / sizeRatioTsu + padding / 2 + size[0] / sizeRatioKu + padding / 2
            }}
            width={size[0] / sizeRatioRa} height={size[0] / sizeRatioRa / ratioRa}
            viewBox="0 0 77.000000 99.000000"
            preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,99.000000) scale(0.100000,-0.100000)"
              fill={colors[4]} stroke="none"
              style={{
                WebkitTransition: `all ${intervalSec}s ease`,
                MozTransition: `all ${intervalSec}s ease`,
              }}
            >
            <path d="M133 980 c-12 -5 -26 -18 -32 -29 -16 -30 -13 -152 4 -175 18 -23 5
            -36 -36 -36 -14 0 -35 -10 -47 -23 -21 -22 -22 -33 -22 -189 0 -152 2 -167 21
            -192 19 -24 26 -26 97 -26 74 0 81 2 147 43 111 69 167 82 225 52 51 -26 54
            -104 7 -139 -26 -19 -43 -21 -204 -23 -214 -2 -213 -1 -213 -108 0 -55 4 -78
            17 -94 26 -31 116 -44 258 -38 188 8 294 51 365 149 41 56 53 108 48 203 -6
            124 -56 202 -162 257 -60 32 -218 37 -303 10 -34 -11 -65 -17 -68 -14 -3 3 -5
            22 -5 42 0 22 -8 48 -20 63 -26 33 -25 38 4 33 13 -2 105 -6 206 -8 217 -4
            210 -7 210 112 0 105 -14 118 -141 130 -118 12 -327 12 -356 0z"/>
            </g>
          </svg>
          <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            style={{
              position: "absolute",
              top: padding,
              left: size[0] / 4.3 + size[0] / sizeRatioTsu + padding / 2 + size[0] / sizeRatioKu + padding / 2 + size[0] / sizeRatioRa + padding
            }}
            width={size[0] / sizeRatioKu} height={size[0] / sizeRatioKu / ratioKu}
            viewBox="0 0 72.000000 98.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,98.000000) scale(0.100000,-0.100000)"
              fill={colors[5]} stroke="none"
              style={{
                WebkitTransition: `all ${intervalSec}s ease`,
                MozTransition: `all ${intervalSec}s ease`,
              }}
            >
            <path d="M448 970 c-16 -5 -47 -23 -70 -41 -86 -67 -274 -228 -310 -265 -69
            -72 -86 -160 -47 -240 22 -47 139 -163 324 -321 l119 -103 113 0 c104 0 113 2
            127 22 36 52 45 41 -378 439 l-60 57 35 32 c19 17 116 97 217 177 183 147 208
            175 185 212 -5 9 -21 22 -34 28 -27 14 -178 16 -221 3z"/>
            </g>
          </svg>
        </div>
        <div style={{fontSize: size[1] / 2.5, fontFamily: "RubikPuddles-Regular"}}>
          {panelDate}
        </div>
        <div>
          {panelTime}
        </div>
      </header>
    </div>
  );
}

export default App;
